import React, { useState } from 'react';
import './main-search-words-text.css';

const SearchWordsText: React.FC = () => {
    return(
        <div className="main-search-words-text-container">
            <p>SearchWordsText</p>
        </div>
    );
};

export default SearchWordsText;
