import React, { useEffect, useState, useRef } from "react";
import "./games-calendar-get.css";

interface Game {
  link: string;
  image: string;
  nameGame: string;
  platform: string;
  date: string;
}

interface ServerResponse {
  month: string;
  games: Game[];
}

const GamesCalendarGet: React.FC = () => {
  const [data, setData] = useState<ServerResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const scrollRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/php/pars-games-calendar/pars-games.php");
        if (!response.ok) {
          throw new Error("Ошибка загрузки данных с сервера");
        }
        const jsonData: ServerResponse = await response.json();
        setData(jsonData);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Загрузка информации...</div>;
  }

  if (error) {
    return <div>Ошибка: {error}</div>;
  }

  if (!data || data.games.length === 0) {
    return <div>Нет доступной информации</div>;
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - (scrollRef.current?.offsetLeft || 0));
    scrollRef.current?.classList.add("dragging");
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !scrollRef.current) return;
    const x = e.pageX - (scrollRef.current.offsetLeft || 0);
    const walk = x - startX;
    scrollRef.current.scrollLeft -= walk; // Скролл при движении мыши
    setStartX(x);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    scrollRef.current?.classList.remove("dragging");
  };

  return (
    <div className="games-list-container">
      <p className='second-block-name-block'>Календарь выхода игр { data.month }</p>

      <div className="games-list">
        {data.games.map((game, index) => (
          <a
            key={index}
            className="games-list-item"
            href={game.link}
            target="_blank"
            rel="noopener noreferrer"
            ref={scrollRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <div className="item-game-info">
              <div className="item-game-img-block">
                <img
                  className="item-game-image"
                  src={game.image}
                  alt={game.nameGame}
                />
              </div>
              <div className="item-game-component">
                <p className="item-game-name-game">
                  {game.nameGame.length > 11 ? `${game.nameGame.slice(0, 11)}...` : game.nameGame}
                </p>
                <p className="item-game-platform">
                  {game.platform.length > 11 ? `${game.platform.slice(0, 11)}...` : game.platform}
                </p>
                
                <p className="item-game-date">{game.date}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default GamesCalendarGet;
