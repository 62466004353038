import React, { useEffect, useState } from "react";

interface CarouselProps {
    resurs: {
        id: number;
        nameWork: string;
        urlWork: string;
        imgWork: string;
    }[];
}

const Carousel: React.FC<CarouselProps> = ({ resurs }) => {
    const [icoint, setIcoint] = useState<number>(0);
    const [classNextWork, serClassNextWork] = useState<string>("carousel-my-work");
    const secNextSlide: number = 7000;

    useEffect(() => {
        const intervalId = setInterval(() => {
            serClassNextWork("next-work");

            setTimeout(() => {
                serClassNextWork("carousel-my-work");
            }, 1500);

            setTimeout(() => {
                setIcoint((prevIcoint) => (prevIcoint + 1) % resurs.length);
            }, 1000);
            
        }, secNextSlide);

        return () => clearInterval(intervalId);
    }, [resurs.length, secNextSlide]);



    return (
        <div>
            <a key={resurs[icoint].id} href={resurs[icoint].urlWork} className="carousel-my-work-container">
                <img
                    className={`carousel-my-work ${classNextWork}`}
                    src={resurs[icoint].imgWork}
                    alt={resurs[icoint].nameWork}
                />
                <p className={`carousel-my-work-name ${classNextWork}`}>{resurs[icoint].nameWork}</p>
            </a>
        </div>
    );
};

export default Carousel;
