import { NavLink } from 'react-router-dom';
import { useState } from 'react';

let indexFerstIcon: number = 0;

function routersArray() {
	interface Item {
		index:any;
    	name: string;
		path: string;
		pathimg: string;
		pathimg_2: string;
	}

	const linksPath: Item[] = [
		{index: 0, name:'Главная', path:"./", pathimg:`${process.env.PUBLIC_URL}/assets/link-sidebar/rectangle-1.png`, pathimg_2:`${process.env.PUBLIC_URL}/assets/link-sidebar/rectangle-2.png`},
		{index: 1, name:'Сайты', path:"./dev-sites", pathimg:`${process.env.PUBLIC_URL}/assets/link-sidebar/tables-1.png`, pathimg_2:`${process.env.PUBLIC_URL}/assets/link-sidebar/tables-2.png`},
		{index: 2, name:'Приложения', path:"./web-aplications", pathimg:`${process.env.PUBLIC_URL}/assets/link-sidebar/credit-card-1.png`, pathimg_2:`${process.env.PUBLIC_URL}/assets/link-sidebar/credit-card-2.png`},
	];

	const transformedArray:any = linksPath.map(element => {
		const [iconImg, setIconImg] = useState(element.pathimg);

		const handleMouseEnter = () => {
			setIconImg(element.pathimg_2);
		};

		const handleMouseLeave = () => {
			setIconImg(element.pathimg);
		};

		const visualActiveIcon = (index:any) => {
			const allActiveIcon:any = document.getElementsByClassName("link-img-active");
			const allPassiveIcon:any = document.getElementsByClassName("link-img");
			const leftSidebarBurger:any = document.getElementsByClassName("left-sidebar-burger");
			const imgBurgerMenu:any = document.getElementsByClassName("img-burger-arraw__close");

			console.log(leftSidebarBurger[0]);

			allPassiveIcon[index].style.opacity = "0";

			for (let i = 0; i < allActiveIcon.length; i++) {
				allPassiveIcon[i].style.opacity = "1";
				allActiveIcon[i].style.opacity = "0";
			}

			allActiveIcon[index].style.opacity = "1";
			allPassiveIcon[index].style.opacity = "0";

			if (window.innerWidth <= 768) {
				leftSidebarBurger[0].style.display = "none";
				imgBurgerMenu[0].className = "img-burger-arraw__open";
			}
		}

		const ferstActiveIcon = () => {
			const ferstIcon:any = document.getElementsByClassName("link-img");
			const ferstActiveIcon:any = document.getElementsByClassName("link-img-active");

			ferstIcon[0].style.opacity = "0";
			ferstActiveIcon[0].style.opacity = "1";
		}

		setTimeout(() => {
			if (indexFerstIcon === 0){
				ferstActiveIcon();
				indexFerstIcon = 1;
			}
		}
		, 100);

		return (
				<NavLink
					to={ element.path }
					className={ ({ isActive }) => isActive ? "link link_active" : "link" }
					key={ element.index }
					onMouseEnter={ handleMouseEnter }
					onMouseLeave={ handleMouseLeave }
					onClick={ ()=> visualActiveIcon(element.index) }
					
					>
					<div className="img-box">
						<img className="link-img" src={ iconImg } alt={ element.name } />
						<img className="link-img-active" src={ element.pathimg_2 } alt={ element.name } />
					</div>
					<p className="link-text">{ element.name }</p>
				</NavLink>
			)
		}
	);

	return transformedArray;
}

function Root() {
  return (
		<div className="links-router">
			{ routersArray() }
		</div>
  );
}

export default Root;