import { Route, Routes } from 'react-router-dom';
import './app.css';
import MainApp from './MainApp';
import OnlineNotiseApp from './web-notise-online/web-notise/OnlineNotiseApp';
import MainContainer from './components/dev-site/site-morent/morent-main';

function App() {

  return (
    <div className="app">
        <Routes>
          <Route path="*" element={<MainApp />} />
          <Route path="web-aplications/web-notise/online-notice" element={<OnlineNotiseApp />} />
          <Route path="dev-sites/site-morent" element={<MainContainer />} />
        </Routes>
    </div>
  );

}

export default App;