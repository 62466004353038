import FadeInSection from '../fadeinsection/Fadeinselection';
import FadeInSectionRight from '../fadeinsection/Fadeinsectionright';
import './devsite.css';

function Devsite() {
	interface Item {
		index:any;
    	name: string;
		path: string;
		pathImgSite: string;
	}

	const listSites: Item[] = [
        {index: 1, name:'MORENT', path:"/dev-sites/site-morent", pathImgSite:"./assets/dev-site/morent.jpg"},
        {index: 0, name:'Site 1', path:"./Site 1", pathImgSite:"./assets/dev-site/OIG4.8JSqyvZUv.jpg"},
		{index: 2, name:'Site 3', path:"./Site 3", pathImgSite:"./assets/dev-site/OIG4.8JSqyvZUv.jpg"},
		{index: 3, name:'Site 4', path:"./Site 4", pathImgSite:"./assets/dev-site/OIG4.8JSqyvZUv.jpg"},
        {index: 4, name:'Site 4', path:"./Site 4", pathImgSite:"./assets/dev-site/OIG4.8JSqyvZUv.jpg"},
	];

    const itemsSiteList:any = listSites.map(element => {

        return (
            <a href={ element.path } className="dev-sites-block" id={ element.index }>
                <div className="dev-img-box">
                    <img className="dev-site-img" src={ element.pathImgSite } alt={ element.name } />
                </div>
                <p className="site-name-text">{ element.name }</p>
            </a>
        )
    });

    return (
        <div className="sites-box">
            <FadeInSection>
                <div className="sites-box-description">
                    <p>Здесь отражены мои сайты, которые были выполнены с использванием REACT + TYPESCRIPT для фронтенд части и PHP для бэкэнд.</p>
                    <div className="img-sites-box">
                        <img src="./assets/dev-site/OIG4.8JSqyvZUv.jpg" alt="img" />
                    </div>
                </div>
            </FadeInSection>
            <FadeInSectionRight>
                <div className="sites-box-items">
                    { itemsSiteList }
                </div>
            </FadeInSectionRight>
        </div>
    )
}
  
export default Devsite;