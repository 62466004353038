import { Route, Routes } from 'react-router-dom';
import AboutMe from '../app-layers/aplications/about-me';
import Devsite from '../dev-site/devsite';
import Webaplications from '../web-aplications/webaplications';
import Webnotise from '../web-aplications/web-notise/Webnotise';
import OnlineNotiseApp from '../../web-notise-online/web-notise/OnlineNotiseApp';
import MainTextPowerpoint from '../web-aplications/text-powerpoint/main-text-powerpoint';
import SearchWordsText from '../web-aplications/search-words-text/main-search-words-text';


const RenderRouters = () => {
	return (
		<Routes>
			<Route path="*" element={<AboutMe />} />
			<Route path="dev-sites" element={<Devsite />} />
			<Route path="web-aplications" element={ <Webaplications /> } />
			<Route path="online-advertisements" element={ <Devsite /> } />
			<Route path="telergam-app" element={ <AboutMe /> } />
			<Route path="after-effects-sample" element={ <AboutMe /> } />
			<Route path="web-aplications/web-notise" element={<Webnotise />} />
			<Route path="web-aplications/web-notise/online-notice" element={<OnlineNotiseApp />} />
			<Route path="web-aplications/text-powerpoint" element={<MainTextPowerpoint />} />
			<Route path="web-aplications/search-words-text" element={<SearchWordsText />} />

		</Routes>
	);
}

export default RenderRouters;