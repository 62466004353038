import { Link } from 'react-router-dom';
import './left-sidebar.css';
import Root from './link-sidebar/routers-links';

const LeftSidebar = () => {
	return (
		<div className="left-sidebar">
			<div className="app-header">
				<div className="box-logo">
					<Link to="./" className='logo'>
						<video
							src="/assets/logo/logo.webm"
							muted
							autoPlay
							loop 
						/>
					</Link>
				</div>
			</div>
			<div className="routers">
				<Root />
			</div>
		</div>
	);
};

export default LeftSidebar;