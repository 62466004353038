import React, { useEffect, useState } from "react";

interface Article {
  title: string;
  url?: string;
  imgurl: string;
  description: string;
  postDate: string;
  author: string;
  link: string;
}

const NewsJsonGet: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [activeClass, setActiveClass] = useState("news-link-open");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/php/pars-news/index.php");
        if (!response.ok) {
          throw new Error("Ошибка загрузки данных с сервера");
        }
        const data = await response.json();
        setArticles(data);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveClass("news-link-close");

      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === articles.length - 1 ? 0 : prevIndex + 1
        );
        setActiveClass("news-link-open");
      }, 1000);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [articles]);

  if (loading) {
    return <div>Загрузка новостей...</div>;
  }

  if (error) {
    return <div>Ошибка: {error}</div>;
  }

  if (articles.length === 0) {
    return <div>Нет доступных новостей</div>;
  }

  const currentArticle = articles[currentIndex];

  return (
    <span>
      <a
        className={activeClass}
        href={currentArticle.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="news-detals-name-img">
          <div className="news-detals-img">
            <img
              className="img-news"
              src={currentArticle.imgurl}
              alt={currentArticle.title}
            />
          </div>
          <p className="name-news">«{currentArticle.title}...»</p>
        </div>
        <div className="news-detals">
          <p className="date-news">{currentArticle.postDate}</p>
          <p className="author-news"> / {currentArticle.author}</p>
        </div>
      </a>
    </span>
  );
};

export default NewsJsonGet;
